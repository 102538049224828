import React from "react";
import {Navigate, useRoutes} from "react-router-dom";
import Terms from "../partialscomponents/termscond";
import HomePage from "../pages/HomePage";
import Privacy from "../partialscomponents/privacy"
import Refund from "../partialscomponents/refund"
import PriceTable from "../components/PriceTable";
import Technology from "../components/Technology";
import Contact from "../components/Contact";
import About from "../components/About";

function AppRouter(){
    const routes =[
        {
            path:"/",
            // element:<HomePage/>,
            children:[
                {
                    path:"/",
                    element:<Navigate to="/home" replace/>
                },
                {
                    path:"home",
                    element:<HomePage/>
                },
                {
                    path:"/terms",
                    element:<Terms/>
                },
                {
                    path:"/privacy",
                    element:<Privacy/>
                },
                {
                    path:"/refund",
                    element:<Refund/>
                },
                {
                    path:"/pricing",
                    element:<PriceTable />
                },
                {
                    path:"/technology",
                    element:<Technology/>
                },
                {
                    path:"/contact",
                    element:<Contact />
                },
                {
                    path:"/about",
                    element:<About />
                }
            ]

        },
        
    ];
    return useRoutes(routes);
}

export default AppRouter;