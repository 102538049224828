
import { useReducer } from "react";
import {BrowserRouter,Routes,Route} from "react-router-dom";
import Router from "../src/router/index";
import Home from "./pages/HomePage";
import { AppContext } from "./appcontext";

function App() {
    const globalState = {
        loading: "",
    }
    const appReducer =  (state: any, action: any) => {
        const{type,payload}=action
        switch (type) {
            case "SET_GLOBAL":
                return { ...state, loading: payload };
        
            default:
                return state;
        }
      };
    const [state, dispatch] = useReducer(appReducer, globalState);
    const store={
        state,
        dispatch
    };
    return (
       <BrowserRouter>
        <AppContext.Provider value={store}>
            <Routes>
                <Route path="/" element={<Home/>}/>
            </Routes>
            <Router/>
        </AppContext.Provider>
        </BrowserRouter>
    );
}export default App;
