import React, { Suspense, useEffect, useState } from "react";
import { Link } from "react-router-dom";

const LottyTree = React.lazy(() => import("./Treeanimation"));

const Hero: React.FC = () => {
  const [isVisible, setIsVisible] = useState<boolean>(true);

  /* start code for conditional rendring of div*/
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= 1200);

  // Function to handle screen size changes
  const handleResize = () => {
    // Check if screen is wider than 768px
    if (window.innerWidth >= 1200) {
      setIsWideScreen(true);
    } else {
      setIsWideScreen(false);
    }
  };

  // Effect to add event listener for resize
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Check the initial screen size on mount
    handleResize();

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  /* end code of conditional rendring of div */
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        // Adjust the threshold as needed
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    // <div className="container-fluid hero-section">
    //   <section id="hero">
    //     <div className="container">
    //       <div className="row hero-height" id="hero-two">
    //         <div className="col-12 col-sm-12 col-md-8" id="left-side">
    //           <div id="hero-content" className="mt-lg-4">
    //             <div className="hero-text text-primary">
    //               <h1 className="fw-bold mb-3 mb-lg-4 display-4">
    //                 Transforming Business Communication Chaos into Serene Efficiency
    //               </h1>
    //               <p className="my-1 my-lg-3 para">
    //                 One stop for all business calling needs. Our solutions are designed to streamline communication across your entire organization, ensuring seamless information flow and clarity at every step. Gain valuable insights into your communication patterns with our comprehensive analytics tools.
    //               </p>
    //             </div>
    //             <div>
    //               <Link to="/pricing">
    //                 <button className="btn btn-primary mt-3 mt-lg-4 mb-4 mb-md-0">
    //                   View Plans
    //                 </button>
    //               </Link>
    //             </div>
    //           </div>
    //         </div>
    //         <div className="col-12 col-sm-12 col-md-4" id="right-img">
    //           <div className="right-container">
    //             <div className="img-fluid">
    //               <div className="img-container">
    //                 <Suspense fallback={null}>
    //                   <LottyTree />
    //                 </Suspense>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </section>
    //   <div className={`downcircle ${isVisible ? '' : 'hidden'}`}>
    //     <i className="bi bi-chevron-double-down"></i>
    //   </div>
    // </div>

    <div className="container-fluid main-content">
      <div className="row pb-sm-0 inner-con">
        <div className="col-12 col-xl-6 d-flex hero-left align-items-xl-center">
          <div className="leftone">
            <div className="pb-2 pb-sm-4 hero-heading">
              <h1 className="fw-bold display-4">
                Transforming Business Communication Chaos into{" "}
                <span>Serene Efficiency</span>
              </h1>
            </div>
            {!isWideScreen && (
              <div className="col-12 col-xl-6 lotty-container d-flex">
                <div className="rightone">
                  <Suspense fallback={null}>
                    <LottyTree />
                  </Suspense>
                </div>
              </div>
            )}
            <div className="pe-sm-0 pb-sm-4 hero-para">
              <p>
                One stop for all business calling needs. Our solutions are
                designed to streamline communication across your entire
                organization, ensuring seamless information flow and clarity at
                every step. Gain valuable insights into your communication
                patterns with our comprehensive analytics tools.
              </p>
            </div>
            <div className="plans-btn">
              <Link to="/pricing">
                <button className="btn btn-primary">View Plans</button>
              </Link>
            </div>
          </div>
        </div>
        {isWideScreen ? (
          <div className="col-12 col-xl-6 lotty-container d-flex justify-content-end">
            <div className="rightone">
              <Suspense fallback={null}>
                <LottyTree />
              </Suspense>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Hero;
