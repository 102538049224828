import React, {Suspense, useState, useEffect } from "react";
import Header from './Header'; 
import Loader from './Loader';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const Footer = React.lazy(() => import('./Footer'));
const mobile = process.env.REACT_APP_CONTACT_MOBILE
const mail = process.env.REACT_APP_SEND_EMAIL
const Contact = () => {
    useEffect(()=>{
        window.scrollTo(0,0)
      },[])
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        tel: '',
        message: ''
    });
    const [submitting, setSubmitting] = useState(false);
    const [showModal,setShowModal]=useState(false)
    const handleChange = (e: { target: { name: any; value: any; }; }) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setSubmitting(true);
        try {
            const response = await fetch(process.env.REACT_APP_BACKEND_URL + 'contact', {
                method: 'POST',
                body: JSON.stringify(formData) });
           if (!response.ok) {
                throw new Error('Network response was not ok'); }
            setTimeout(() => {
                setFormData({
                    name: '',
                    email: '',
                    tel: '',
                    message: ''
                });
                setSubmitting(false);
                setShowModal(true);
                setTimeout(() => {
                    setShowModal(false);
                }, 3000);
            }, 2000);
        } catch (error) { 
            console.error('Error submitting form:', error);}
    };
    return ( <div>
            <Header />
          <div className="container-fluid ContactPage"><div id="contact" className='ContactForm max-w-2xl  m-6 mx-auto py-8'>
            {submitting && <Loader />} 
                <form method="POST" onSubmit={handleSubmit} className="contact-form py-8 px-8">
                    <h1 className="text-primary">Get In Touch</h1>
                    <input type='text' name='name' value={formData.name} onChange={handleChange} placeholder="Enter Your Name" required />
                    <input type='email' name='email' value={formData.email} onChange={handleChange} placeholder="Enter Your Email Address" required />
                     <input type='tel' name='tel' value={formData.tel}  pattern="[0-9]{10}" onChange={handleChange} placeholder="Enter Your Contact Number" required />
                    <textarea rows={3} name='message' value={formData.message} onChange={handleChange} className='form-control formInput' placeholder='Message' required></textarea>
                    <button type="submit">Send Message</button> </form>
                {showModal && (
                //    <div className="modal-container"><div className="modal mx-auto">
                //        <p className="fw-bold text-xl">Message Sent Successfully !!</p></div></div> 
                <div className="modal-container">
                <div
      className="modal show thankyou_pop_up"
    >
      <Modal.Dialog>
        <Modal.Header>
        <i className="bi bi-check-circle-fill thanyouicon"></i>
        </Modal.Header>
        <Modal.Body>
        <Modal.Title>Thank You!</Modal.Title>
          <p>We will contact you soon!</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>Ok</Button>
        </Modal.Footer>
      </Modal.Dialog>
    </div>
                </div>       )}
                <div className='contact-container'> 
                <h2 className="text-primary">Contact Info</h2>
                    <div className='contact-info'><h5>Let's Talk</h5><div className="contact-one">
                       <span className="con-tact"><a href="tel:7647868764"><i className="bi bi-telephone Con-details"></i></a></span> <span className="details">{mobile}<a href="tel:7647868764">764 7868 764</a></span> </div>
        <div className="contact-one"><span className="con-tact"><a href="mailto:hello@receptionmonk.com"><i className="bi bi-envelope Con-details"></i></a></span> <span className="details">{mail}<a href="mailto:hello@receptionmonk.com">hello@receptionmonk.com</a></span></div></div>
         <div className='contact-info visit'><h5>Visit Us</h5>
        <address>269, 2nd Floor,Napier Town, Opp. Bhawartal Garden Jabalpur, M.P. India (482001)</address></div>
        <div className='social-icons'><a href='https://www.facebook.com/receptionmonk'><i className='bi bi-facebook'></i></a>
                        <a href='https://twitter.com/receptionmonk'><i className='bi bi-twitter'></i></a>
                        <a href='https://instagram.com/receptionmonk'><i className='bi bi-instagram'></i></a>
                        <a href='https://in.linkedin.com/company/receptionmonk'><i className='bi bi-linkedin'></i></a>
                    </div></div></div></div> 
           <Suspense fallback={null}> <Footer /> </Suspense> </div>
    )};export default Contact;