import { Link } from "react-router-dom";
// @ts-ignore
import logo from "../assets/images/rmtext.svg"
function Footer() {
    const handleScrollToTop = () => {
        window.scrollTo(0, 0);
      };
    return ( <div>
            <footer><section id="footer" className="bg-primary">
               <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-4 d-flex aligned-center footer-logo">
                                <div>
                                    <img src={logo} alt="logo" title="app-logo" height={"120px"} width={"210px"}></img>
                                    <div className="d-flex social mt-3">
                                    <a href="https://www.facebook.com/receptionmonk" aria-label="receptionmonk"><i className='bi bi-facebook footer-icons'></i></a>
                                   <a href="https://twitter.com/receptionmonk" aria-label="receptionmonk"><i className='bi bi-twitter footer-icons'></i></a>
                                   <a href="https://instagram.com/receptionmonk" aria-label="receptionmonk"><i className='bi bi-instagram footer-icons'></i></a>
                                   <a href="https://in.linkedin.com/company/receptionmonk" aria-label="receptionmonk"><i className="bi bi-linkedin footer-icons"></i></a>
                                    </div>
                                </div>                               
                            </div>
                            <div className="col-12 col-sm-12 col-md-4 d-flex aligned-center office">                             
                               <div className="comp-address mt-2 mt-md-0">
                               <h2 className="footer-heading">Contact</h2>
                                 <p className="footer-para">269, 2nd Floor, Napier Town, Opp. Bhawartal Garden Jabalpur, M.P. India (482001)</p>
                                 <p className="footer-para"><a href="mailto:hello@receptionmonk.com">hello@receptionmonk.com</a></p>
                                 <p className="footer-para"><a href="tel:7647868764">+91-7647868764</a></p>
                               </div></div>
                            <div className="col-12 col-sm-12 col-md-4 d-flex aligned-center">              
                                <div className="d-flex justify-content-start justify-content-md-center flex-column mt-1 mt-md-0">
                                <h2 className="footer-heading footer-link">Links</h2>
                                    <ul className="list-group mt-1">      
                                        <li className="list-group-item"><Link to="/about" onClick={handleScrollToTop}>About us</Link></li>
                                        <li className="list-group-item"><Link to="/contact" onClick={handleScrollToTop}> Contact us</Link></li>              
                                        <li className="list-group-item"><Link to="/terms" onClick={handleScrollToTop}>Terms of Usage</Link></li>
                                        <li className="list-group-item"><Link to="/privacy" onClick={handleScrollToTop}>Privacy Policy</Link></li>         
                                        <li className="list-group-item"><Link to="/refund" onClick={handleScrollToTop}>Refund</Link></li>                          
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="d-flex justify-content-center mt-4">
                                <h5 className="cpy-right">Copyright &copy; 2024 | ReceptionMonk</h5>
                            </div>
                        </div>
                    </div>
                </section>
            </footer>
        </div>
    );
}export default Footer;